import logo from "./logo.svg";
import "./App.css";
import Login from "./Login";
import { useState } from "react";
import Loader from "./Loader";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="App">
      <header className="App-header">
        <p>Eiffage Immobilier</p>
        <p>Accueil SI Métier</p>
      </header>
      <div className="content">
        <section>
          <img src={logo} className="App-logo" alt="logo" />
        </section>
        <section>
          <p className="paragraphMargin5">
            Votre accès au SI Métier Eiffage Immobilier est créé, vous pouvez
            maintenant accédez aux différents Reporting BI qui vous ont été
            transmis.
          </p>
        </section>
        <section>
          <Login setIsLoading={setIsLoading} />
        </section>
      </div>
      <div className="footer"></div>
      <Loader visible={isLoading} message={"Authentification en cours..."} />
    </div>
  );
}

export default App;
