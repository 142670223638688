import React, { useCallback, useEffect, useMemo } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  InteractionType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import "./Login.css";

export default function Login(props) {
  const { setIsLoading } = props;
  const request = useMemo(
    () => ({
      //loginHint: "name@example.com",
      scopes: ["User.Read"],
    }),
    []
  );

  const { login, result, error } = useMsalAuthentication(
    InteractionType.Silent,
    request
  );
  const { accounts, instance } = useMsal();

  const logout = useCallback(
    async (homeAccountId) => {
      const currentAccount = instance.getAccountByHomeId(homeAccountId);
      await instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: "https://contoso.com/loggedOut",
      });
    },
    [instance]
  );

  const withLoading = useCallback(
    async (prom) => {
      setIsLoading(true);
      await prom();
      setIsLoading(false);
    },
    [setIsLoading]
  );

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      withLoading(() => login(InteractionType.Redirect, request));
    } else if (!error) {
      console.log("erreur ", error);
    }
  }, [error, withLoading, login, request]);

  return (
    <div className="siteSection">
      <div className="siteSectionTitre"></div>
      <div className="siteSectionContent center">
        <AuthenticatedTemplate>
          <p>
            Authentifié en tant que : <em>{accounts[0]?.username}</em>
            <a
              href="#"
              style={{ fontSize: "0.6rem", marginLeft: "0.5rem" }}
              onClick={() => logout(accounts[0]?.homeAccountId)}
            >
              Déconnexion
            </a>
          </p>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p>
            Vous allez être redirigé vers le site d'authentification du tenant
            Eiffage Immobilier.
          </p>
        </UnauthenticatedTemplate>
        {error && <div style={{ fontSize: "0.6rem"}}>{`Erreur : ${error}`}</div>}
      </div>
    </div>
  );
}
