import "./Loader.css";

function Loader({ visible, message }) {
  return (
    visible && (
      <div className="divFondProgressIndicator">
        <div className="progressIndicator">
          <p>{message}</p>
          <div className="progress-bar">
            <div className="progress-bar-value"></div>
          </div>
        </div>
      </div>
    )
  );
}

const progressStyles = {
  itemName: { color: "white" },
};

export default Loader;
