import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

  if (!process.env.REACT_APP_AZURE_AD_CLIENT_ID)
    throw Error(
      "la variable d'environnement AZURE_AD_CLIENT_ID n'est pas défini."
    );
  if (!process.env.REACT_APP_AZURE_AD_TENANT_ID)
    throw Error(
      "la variable d'environnement AZURE_AD_TENANT_ID n'est pas défini."
    );

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
  },
};


const pca = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
